/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'boombox-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M14 0a.5.5 0 01.5.5V2h.5a1 1 0 011 1v2H0V3a1 1 0 011-1h12.5V.5A.5.5 0 0114 0M2 3.5a.5.5 0 101 0 .5.5 0 00-1 0m2 0a.5.5 0 101 0 .5.5 0 00-1 0m7.5.5a.5.5 0 100-1 .5.5 0 000 1m1.5-.5a.5.5 0 101 0 .5.5 0 00-1 0M9.5 3h-3a.5.5 0 000 1h3a.5.5 0 000-1M6 10.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0m-1.5.5a.5.5 0 100-1 .5.5 0 000 1m7 1a1.5 1.5 0 100-3 1.5 1.5 0 000 3m.5-1.5a.5.5 0 11-1 0 .5.5 0 011 0"/><path pid="1" d="M0 6h16v8a1 1 0 01-1 1H1a1 1 0 01-1-1zm2 4.5a2.5 2.5 0 105 0 2.5 2.5 0 00-5 0m7 0a2.5 2.5 0 105 0 2.5 2.5 0 00-5 0"/>',
    },
});
